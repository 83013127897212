import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import MontageGif from "./Images/image1.gif";
import "./styles.css";
import ResponsiveImageGrid from "./components/Tilegrid/TileGrid";
import FrontPage from "./components/FrontPage/FrontPage";
import BackgroundImage from "./Images/Black_Smiley_Face.jpg";
import BearHand from "./Images/Bear_highfive.png";
import SkyBackground from "./Images/sky.gif";
import GoldloxeFeet from "./Images/Goldloxe_forfeet.png";
import JRstreet from "./Images/juststreet.jpg";
import JR from "./Images/JR.png";
import SlapsLogo from "./Images/Slaps/Slaps-Logo.png";
import Slaps from "./components/Slaps/Slaps"; // Ensure this component exists

import gifimg1 from "./Images/gifgrid/drawflip2.gif";
import gifimg2 from "./Images/gifgrid/paint.gif";
import gifimg3 from "./Images/gifgrid/paste.gif";
import gifimg4 from "./Images/gifgrid/street5.gif";
import gifimg5 from "./Images/gifgrid/walkingstreets.gif";
import gifimg6 from "./Images/gifgrid/robot.gif";

const gifgridImages = [gifimg1, gifimg2, gifimg3, gifimg5, gifimg6, gifimg4];

export default function App() {
  const [isSetup, setIsSetUp] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsSetUp(true);
    }, 1000);
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <FrontPage />
                <div className="montage-container">
                  <img loading="lazy" src={MontageGif} alt="flashing goldoxe works" />
                  {/* <Link to="/Slaps">
                    <img className="slaps-button" src={SlapsLogo} alt="Slaps Logo" />
                  </Link> */}
                </div>

                <div className="Section sOne" style={{ backgroundImage: `url(${BackgroundImage})` }}>
                  <div className="sectionText">
                    <h3>The infamous NYC street artist @goldloxe has been trespassing on walls since 2015.. reminding us we have already won the cosmic lottery</h3>
                  </div>
                  <div className="gifgrid">
                    <ResponsiveImageGrid images={gifgridImages}/>
                  </div>
                </div>

                <div className="Section sTwo">
                  <div className="sTwoBackground" style={{ backgroundImage: `url(${SkyBackground})` }}>
                    <img loading="lazy" className="bear-hand" src={BearHand} />
                    <div className="sectionText">
                      <h3> This philosophy stems from ‘The Goldilocks Zone’, the scientific term for our planet’s perfect distance from the sun..</h3>
                    </div>
                    <div className="sectionText">
                      <h3> Not too hot. Not too cold. Just Right!</h3>
                    </div>
                  </div>
                </div>

                <div className="Section Slaps">
                    <Slaps />
                </div>

                <div className="Section sThree" style={{ backgroundImage: `url(${BackgroundImage})` }}>
                  <div className="goldloxe-forfeet-container" style={{ backgroundImage: `url(${GoldloxeFeet})` }}></div>
                  <div className="sectionText">
                    <h3>Goldloxe is bridging the worlds of street art and web3 with an experienced team from <br /> Channel 4<br /> Vice Media<br /> NPR<br /> TIME </h3>
                    <br/>
                    <h3>For advisor roles and collaborations contact: justrightlandy@gmail.com</h3>
                  </div>
                </div>

                <div className="Section sFour" style={{ backgroundImage: `url(${JRstreet})` }}>
                  <img loading="lazy" className="JRlogo" src={JR} />
                </div>


              </>
            }
          />


          <Route path="/Slaps" element={<Slaps />} />
        </Routes>
      </div>
    </Router>
  );
}