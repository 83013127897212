import React from "react";
import { FaInstagram, FaTelegram  } from "react-icons/fa"; // Install react-icons
import {FaXTwitter} from "react-icons/fa6";

function Socialsgrid() {
    return <div className="socials-block">
            <div className="socials-background">
                <div className="socials-block-content">
                    <div className="social-icons">
                        <a href="https://x.com/goldloxe" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                        <FaXTwitter />
                        </a>
                        <a href="https://www.instagram.com/goldloxe" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                        <FaInstagram />
                        </a>
                        <a href="https://t.me/+D5IUkP3mfH5lZTI0" target="_blank" rel="noopener noreferrer" aria-label="Telegram">
                        <FaTelegram/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
}

export default Socialsgrid;
