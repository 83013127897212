import React from "react";
import GraffitiWall from "../../Images/Slaps/graffitti-wall.gif"
import MintButton from "../../Images/Slaps/Mint-Button.png";
import PaleBackground from "../../Images/Slaps/Pale-Background.jpg";
import SlapsLogo from "../../Images/Slaps/Slaps-Logo.png";
import ResponsiveImageGrid from "../Tilegrid/TileGrid";
import HomeButton from "../HomeButton/HomeButton";

import AllSeeingPaw from "../../Images/Slaps/11 x TOKEN Gifs /AllSeeingPaw.gif";
import BearTrip from "../../Images/Slaps/11 x TOKEN Gifs /BearTrip.gif";
import CosmicLotteryWinner from "../../Images/Slaps/11 x TOKEN Gifs /CosmicLotteryWinners.gif";
import DiamondHand from  "../../Images/Slaps/11 x TOKEN Gifs /DiamondHand.gif";
import DMTToad from "../../Images/Slaps/11 x TOKEN Gifs /DMTToad.gif";
import GOAT from "../../Images/Slaps/11 x TOKEN Gifs /GOAT.gif";
import LuckyCat from "../../Images/Slaps/11 x TOKEN Gifs /LuckyCat.gif";
import MushyHouse from "../../Images/Slaps/11 x TOKEN Gifs /MushyHouse.gif";
import RollTheDice from "../../Images/Slaps/11 x TOKEN Gifs /RollTheDice.gif";
import SteamPunk from "../../Images/Slaps/11 x TOKEN Gifs /Steampunk.gif";
import TooHot from  "../../Images/Slaps/11 x TOKEN Gifs /TooHot.gif";

const images = [AllSeeingPaw, DiamondHand, MushyHouse, RollTheDice, GOAT
    , BearTrip, SteamPunk, LuckyCat, DMTToad, TooHot, CosmicLotteryWinner];
    function Slaps() {
        return (
            <div className="Slaps">
                <div className="front">
                    <img loading="lazy" className="GraffitiWall" src={GraffitiWall} alt="Graffiti Background" /> 
                    <img loading="lazy" className="slaps-logo" src={SlapsLogo} alt="Slaps Logo" />
                </div>
    
                <div className="artworks">
                    <img loading="lazy" className="pale-background" src={PaleBackground} alt="Pale Background" />
                    <div className="content-layer">
                        <ResponsiveImageGrid images={images} />
                        <h3>Street stickers were never meant to last—until now. Bringing the ethos of slaps on-chain, turning fleeting marks into permanent digital collectibles. 11 x 1,111 moments from the world of @goldloxe. Affordable and payable by card. Join the early supporters of this art movement. </h3>
                        <div className="mint-button-container">
                            <a href="https://www.crossmint.com/collections/slaps-by-goldloxe/drop" target="_blank" rel="noopener noreferrer" aria-label="Telegram">
                                <img loading="lazy" className="mint-button" src={MintButton} /> 
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

export default Slaps;